import { Text, VStack } from "@chakra-ui/react";
import { CkBTCWidget, ChainName, ChainID, AssetType } from "@omnity/widget";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

export default function Ckbtc() {
  const [searchParams, setSearchParams] = useSearchParams();
  const _sourceChain = searchParams.get("sourceChain") || ChainName.Bitcoin;
  const _targetChain = searchParams.get("targetChain") || ChainName.Osmosis;
  const _tokenId = searchParams.get("tokenId");

  const [sourceChain] = useState(_sourceChain);
  const [targetChain] = useState(_targetChain);
  const [tokenId] = useState(_tokenId);

  useEffect(() => {
    document.title = `ckBTC | Omnity Network`;
  }, []);

  return (
    <VStack w={{ base: "100%" }}>
      <Text fontSize={{ base: 18, md: 24 }} mb={4} fontWeight={300}>
        Omnity Hub - BTC
      </Text>
      <CkBTCWidget
        assetType={AssetType.ckbtc}
        customs={ChainID.BitcoinckBTC}
        sourceChain={sourceChain as ChainName}
        targetChain={targetChain as ChainName}
        tokenId={tokenId}
        network="mainnet"
        reversible
        isPeerToPeer={false}
        onParamsChange={(params) => {
          const tc = searchParams.get("targetChain");
          const sc = searchParams.get("sourceChain");
          const ti = searchParams.get("tokenId");

          if (
            !(
              sc === params.sourceChain &&
              tc === params.targetChain &&
              ti === params.tokenId
            )
          ) {
            setSearchParams({
              sourceChain: params.sourceChain ?? "",
              targetChain: params.targetChain ?? "",
              tokenId: params.tokenId ?? "",
            });
          }
        }}
      />
    </VStack>
  );
}
