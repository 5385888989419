import {
  Button,
  HStack,
  Input,
  InputGroup,
  InputRightElement,
  Radio,
  RadioGroup,
  Stack,
  Switch,
  Text,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import InfoTip from "@components/common/InfoTip";
import ConfirmEtch from "@components/SubmitButton/ConfirmEtch";
import { EtchParams, HeightType } from "@types";
import { MAX_DIVISIBILITY, MAX_RUNE_NAME_LENGTH } from "@utils/constants";
import { formatUnits, readableNumber } from "@utils/format";
import { useState } from "react";

enum LogoType {
  None = "None",
  Text = "Text",
  File = "File",
}

const initialParams = {
  rune_name: "",
  symbol: "",
  divisibility: "",
  premine: "",
  turbo: true,
  terms: {
    amount: "",
    cap: "",
    height: ["", ""],
    offset: ["", ""],
  },
} as EtchParams;

export default function Etch({ width }: { width?: number }) {
  const [params, setParams] = useState<EtchParams>(initialParams);
  const [logoType, setLogoType] = useState(LogoType.None);
  const [mintable, setMintable] = useState(true);
  const [heightType, setHeightType] = useState(HeightType.Absolute);

  const boxWrapperBg = useColorModeValue("#eee", "gray.700");
  const inputBoxWrapperBg = useColorModeValue("white", "gray.800");
  const textColor = useColorModeValue("#333", "#eee");
  const inputTextColor = useColorModeValue("gray.800", "gray.200");

  const onChange = (key: string, value: any) => {
    setParams((prev) => {
      if (!prev) {
        return { [key]: value } as EtchParams;
      }
      return { ...prev, [key]: value } as EtchParams;
    });
  };

  return (
    <VStack w={{ base: "100%", md: width }} gap={6}>
      <VStack
        width="99%"
        p={{ base: 4, md: 6 }}
        borderRadius={8}
        gap={4}
        bg={inputBoxWrapperBg}
        color={textColor}
      >
        <VStack w="100%" alignItems="flex-start" gap={1}>
          <HStack w="100%" justifyContent="space-between">
            <Text fontWeight={600}>Name</Text>
            <Text fontSize={12} color="gray.400">
              10~{MAX_RUNE_NAME_LENGTH} characters
            </Text>
          </HStack>
          <InputGroup size="md">
            <Input
              color={inputTextColor}
              bg={boxWrapperBg}
              py={4}
              px={2}
              lineHeight={1}
              borderWidth={0}
              fontFamily="monospace"
              height="100%"
              outline="none"
              placeholder="Enter AAA•AAA•AAA"
              value={params.rune_name}
              onChange={(e) => {
                const value = e.target.value.toUpperCase().trim();
                if (
                  (/^[A-Z]*(•[A-Z]+)*(•){0,}$/.test(value) || value === "") &&
                  value.replace(/•/g, "").length <= MAX_RUNE_NAME_LENGTH
                ) {
                  onChange("rune_name", value);
                }
              }}
            />
            <InputRightElement width="4rem">
              <Button
                colorScheme="blue"
                size="md"
                pos="relative"
                top="5.5px"
                left="6px"
                fontSize={24}
                borderRadius={4}
                onClick={() => {
                  if (params.rune_name.endsWith("•")) {
                    return;
                  }
                  onChange("rune_name", params.rune_name + "•");
                }}
              >
                •
              </Button>
            </InputRightElement>
          </InputGroup>
        </VStack>
        <HStack w="100%" gap={4}>
          <VStack flex={1} alignItems="flex-start">
            <Text fontWeight={600}>Symbol</Text>
            <Input
              color={inputTextColor}
              bg={boxWrapperBg}
              py={4}
              px={2}
              lineHeight={1}
              borderWidth={0}
              fontFamily="monospace"
              height="100%"
              outline="none"
              placeholder=""
              value={params.symbol}
              onChange={(e) => {
                if (Array.from(e.target.value).length > 1) {
                  return;
                }
                onChange("symbol", e.target.value);
              }}
            />
          </VStack>
          <VStack flex={1} alignItems="flex-start">
            <HStack w="100%" justifyContent="space-between">
              <HStack>
                <Text fontWeight={600}>Divisibility</Text>
                <InfoTip tip="the number of decimal places the token can be divided into." />
              </HStack>
              <Text fontSize={12} color="gray.400">
                0~{MAX_DIVISIBILITY}
              </Text>
            </HStack>
            <Input
              color={inputTextColor}
              bg={boxWrapperBg}
              py={4}
              px={2}
              lineHeight={1}
              borderWidth={0}
              fontFamily="monospace"
              height="100%"
              outline="none"
              placeholder=""
              type="number"
              max={MAX_DIVISIBILITY}
              min={0}
              value={params.divisibility}
              onChange={(e) => {
                onChange("divisibility", e.target.value);
              }}
            />
          </VStack>
        </HStack>
        <VStack w="100%" alignItems="flex-start" gap={1}>
          <HStack w="100%">
            <Text fontWeight={600}>Premine</Text>
            <InfoTip tip="the amount of runes minted to your ICP address at genesis." />
          </HStack>
          <Input
            color={inputTextColor}
            bg={boxWrapperBg}
            py={4}
            px={2}
            lineHeight={1}
            borderWidth={0}
            height="100%"
            outline="none"
            placeholder=""
            fontFamily="monospace"
            value={params.premine}
            onChange={(e) => {
              if (
                /^\d+$/.test(e.target.value.trim()) ||
                e.target.value.trim() === ""
              ) {
                onChange("premine", e.target.value.trim());
              }
            }}
          />
          {!!params.premine && (
            <HStack>
              <Text fontSize={12} color={textColor}>
                Displayed as{" "}
                <Text display="inline" color="#008cd5" fontFamily="monospace">
                  {readableNumber(
                    formatUnits(
                      BigInt(params.premine),
                      Number(params.divisibility ?? 0),
                    ),
                  )}{" "}
                  {params.symbol}
                </Text>
              </Text>
              <InfoTip tip="Based on Divisibility setting" size={12} />
            </HStack>
          )}
        </VStack>
        <HStack w="100%" justifyContent="space-between">
          <Text fontWeight={600}>Mintable</Text>
          <Switch
            colorScheme="blue"
            isChecked={mintable}
            onChange={(e) => setMintable(e.target.checked)}
          />
        </HStack>
        {mintable && (
          <HStack w="100%" gap={4} alignItems="flex-start">
            <VStack flex={1} alignItems="flex-start">
              <HStack>
                <Text fontWeight={600}>Amount</Text>
                <InfoTip tip="the amount of runes each mint transaction receives." />
              </HStack>
              <Input
                color={inputTextColor}
                bg={boxWrapperBg}
                py={4}
                px={2}
                lineHeight={1}
                borderWidth={0}
                fontFamily="monospace"
                height="100%"
                outline="none"
                placeholder=""
                value={params.terms.amount}
                onChange={(e) => {
                  const value = e.target.value.trim();
                  if (/^\d+$/.test(value) || value === "") {
                    onChange("terms", {
                      ...params.terms,
                      amount: value,
                    });
                  }
                }}
              />
              {!!params.terms.amount && (
                <HStack>
                  <Text fontSize={12} color={textColor}>
                    Displayed as{" "}
                    <Text
                      display="inline"
                      color="#008cd5"
                      fontFamily="monospace"
                    >
                      {readableNumber(
                        formatUnits(
                          BigInt(params.terms.amount),
                          Number(params.divisibility ?? 0),
                        ),
                      )}{" "}
                      {params.symbol}
                    </Text>
                  </Text>
                  <InfoTip tip="Based on Divisibility setting" size={12} />
                </HStack>
              )}
            </VStack>
            <VStack flex={1} alignItems="flex-start">
              <HStack>
                <Text fontWeight={600}>Cap</Text>
                <InfoTip tip="the allowed number of mints." />
              </HStack>
              <Input
                color={inputTextColor}
                bg={boxWrapperBg}
                py={4}
                px={2}
                lineHeight={1}
                borderWidth={0}
                fontFamily="monospace"
                height="100%"
                outline="none"
                placeholder=""
                value={params.terms.cap}
                onChange={(e) => {
                  const value = e.target.value.trim();
                  if (/^\d+$/.test(value) || value === "") {
                    onChange("terms", {
                      ...params.terms,
                      cap: value,
                    });
                  }
                }}
              />
            </VStack>
          </HStack>
        )}
        {mintable && (
          <VStack w="100%" gap={4}>
            <HStack w="100%" justifyContent="space-between">
              <Text fontWeight={600}>Height Selection</Text>

              <RadioGroup
                onChange={(v) => setHeightType(v as HeightType)}
                value={heightType}
              >
                <Stack direction="row">
                  {Object.values(HeightType).map((value) => (
                    <Radio key={value} value={value}>
                      {value}
                    </Radio>
                  ))}
                </Stack>
              </RadioGroup>
            </HStack>

            <HStack w="100%" gap={4}>
              <VStack flex={1} alignItems="flex-start">
                <HStack>
                  <Text fontWeight={600}>
                    {heightType === HeightType.Absolute ? "Height" : "Offset"}{" "}
                    Start
                  </Text>
                  <InfoTip
                    tip={
                      heightType === HeightType.Absolute
                        ? "The HeightStart and HeightEnd fields contain the mint's starting and ending absolute block heights, respectively. The mint is open starting in the block with height HeightStart, and closes in the block with height HeightEnd."
                        : "The OffsetStart and OffsetEnd fields contain the mint's starting and ending block heights, relative to the block in which the etching is mined. The mint is open starting in the block with height OffsetStart + ETCHING_HEIGHT, and closes in the block with height OffsetEnd + ETCHING_HEIGHT."
                    }
                  />
                </HStack>
                <Input
                  color={inputTextColor}
                  bg={boxWrapperBg}
                  py={4}
                  px={2}
                  lineHeight={1}
                  borderWidth={0}
                  fontFamily="monospace"
                  height="100%"
                  outline="none"
                  placeholder=""
                  value={
                    heightType === HeightType.Absolute
                      ? params.terms.height[0]
                      : params.terms.offset[0]
                  }
                  onChange={(e) => {
                    const value = e.target.value.trim();
                    if (/^\d+$/.test(value) || value === "") {
                      if (heightType === HeightType.Absolute) {
                        onChange("terms", {
                          ...params.terms,
                          height: [value, params.terms.height[1]],
                        });
                      } else {
                        onChange("terms", {
                          ...params.terms,
                          offset: [value, params.terms.offset[1]],
                        });
                      }
                    }
                  }}
                />
              </VStack>
              <VStack flex={1} alignItems="flex-start">
                <HStack>
                  <Text fontWeight={600}>
                    {heightType === HeightType.Absolute ? "Height" : "Offset"}{" "}
                    End
                  </Text>
                  <InfoTip
                    tip={
                      heightType === HeightType.Absolute
                        ? "The HeightStart and HeightEnd fields contain the mint's starting and ending absolute block heights, respectively. The mint is open starting in the block with height HeightStart, and closes in the block with height HeightEnd."
                        : "The OffsetStart and OffsetEnd fields contain the mint's starting and ending block heights, relative to the block in which the etching is mined. The mint is open starting in the block with height OffsetStart + ETCHING_HEIGHT, and closes in the block with height OffsetEnd + ETCHING_HEIGHT."
                    }
                  />
                </HStack>
                <Input
                  color={inputTextColor}
                  bg={boxWrapperBg}
                  py={4}
                  px={2}
                  lineHeight={1}
                  borderWidth={0}
                  fontFamily="monospace"
                  height="100%"
                  outline="none"
                  placeholder=""
                  value={
                    heightType === HeightType.Absolute
                      ? params.terms.height[1]
                      : params.terms.offset[1]
                  }
                  onChange={(e) => {
                    const value = e.target.value.trim();
                    if (/^\d+$/.test(value) || value === "") {
                      if (heightType === HeightType.Absolute) {
                        onChange("terms", {
                          ...params.terms,
                          height: [params.terms.height[0], value],
                        });
                      } else {
                        onChange("terms", {
                          ...params.terms,
                          offset: [params.terms.offset[0], value],
                        });
                      }
                    }
                  }}
                />
              </VStack>
            </HStack>
          </VStack>
        )}

        {/* <VStack w="100%" alignItems="flex-start" gap={1}>
          <HStack w="100%" justifyContent="space-between">
            <Text fontWeight={600}>Logo</Text>

            <RadioGroup
              onChange={(v) => setLogoType(v as LogoType)}
              value={logoType}
            >
              <Stack direction="row">
                {Object.values(LogoType).map((value) => (
                  <Radio key={value} value={value}>
                    {value}
                  </Radio>
                ))}
              </Stack>
            </RadioGroup>
          </HStack>

          {logoType === LogoType.Text && (
            <Input
              color={inputTextColor}
              bg={boxWrapperBg}
              py={4}
              px={2}
              lineHeight={1}
              borderWidth={0}
              height="100%"
              outline="none"
              placeholder="Logo url or base64"
            />
          )}
          {logoType === LogoType.File && (
            <VStack w="100%" alignItems="flex-start" gap={1}>
              <Input
                color={inputTextColor}
                bg={boxWrapperBg}
                py={4}
                px={2}
                type="file"
                lineHeight={1}
                borderWidth={0}
                height="100%"
                outline="none"
                placeholder="Logo url or base64"
              />
              <Text fontSize={14} color={textColor}>
                Accept .jpg, .webp, .png, .gif
              </Text>
            </VStack>
          )}
        </VStack> */}

        <VStack w="100%" alignItems="flex-start" gap={1}>
          <HStack w="100%" justifyContent="space-between">
            <Text fontWeight={600}>Max Supply</Text>
          </HStack>
          <HStack>
            <Text
              color="#008cd5"
              textAlign="left"
              fontWeight={600}
              fontFamily="monospace"
              fontSize={16}
            >
              {readableNumber(
                formatUnits(
                  BigInt(params.premine ?? 0) +
                    (params.terms.amount && params.terms.cap
                      ? BigInt(params.terms.amount) * BigInt(params.terms.cap)
                      : BigInt(0)),
                  Number(params.divisibility ?? 0),
                ),
                Number(params.divisibility ?? 0),
              )}
              <Text
                display="inline"
                color={textColor}
                fontWeight={500}
                wordBreak="break-all"
              >
                {params.premine || (params.terms.amount && params.terms.cap)
                  ? ` = (${params.premine}${params.terms.amount && params.terms.cap ? `${params.premine ? " + " : ""}${params.terms.amount} * ${params.terms.cap}` : ""}) / 10^${params.divisibility || "0"}`
                  : ""}
              </Text>
            </Text>
          </HStack>
        </VStack>
      </VStack>
      <ConfirmEtch
        params={params}
        heightType={heightType}
        mintable={mintable}
        onSubmitted={() => setParams(initialParams)}
      />
    </VStack>
  );
}
