import {
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  VStack,
} from "@chakra-ui/react";
import Transfer from "./tabs/Transfer";
import Burn from "./tabs/Burn";
import { AssetType, OmnityWidgetProps, TabAction } from "@types";
import { useHubContext } from "@context/OmnityHubContext";
import Footer from "@components/common/Footer";
import { ChainSelectProvider } from "@context/ChainContext";
import { TransferProvider } from "@context/TransferContext";
import AddRunes from "./tabs/AddRunes";
import { AddRunesProvider } from "@context/AddRunesContext";
import Mint from "./tabs/Mint";
import { TokenListProvider } from "@context/TokenListProvider";
import Etch from "./tabs/Etch";
import TicketHistory from "@components/TicketHistory";

const TABS = [
  TabAction.Transfer,
  TabAction.Etch,
  TabAction.Mint,
  TabAction.Burn,
  TabAction.AddRunes,
];

export default function Omnity({
  assetType,
  customs,
  width = 464,
  tab,
  sourceChain,
  targetChain,
  tokenId,
  reversible,
  isPeerToPeer,
  onTabChange,
  onParamsChange,
}: OmnityWidgetProps) {
  const { onTabActionChange } = useHubContext();

  let tabs = TABS;
  if (assetType !== AssetType.runes) {
    tabs = [TabAction.Transfer];
  }
  return (
    <TransferProvider
      assetType={assetType}
      sourceChain={sourceChain}
      targetChain={targetChain}
      tokenId={tokenId}
      reversible={reversible}
      isPeerToPeer={isPeerToPeer}
      onParamsChange={onParamsChange}
    >
      <ChainSelectProvider>
        <TokenListProvider>
          <VStack
            id="omnity"
            w={{ base: "100%", md: width }}
            gap="2px"
            px={{ base: 2, md: 0 }}
            mt={{ base: 6, md: 0 }}
          >
            <Tabs
              variant="soft-rounded"
              colorScheme="pink"
              w="100%"
              index={TABS.indexOf(tab ?? TabAction.Transfer)}
            >
              <TabList
                gap={{ base: 2, md: 2 }}
                pos="relative"
                flexWrap={{ base: "wrap", md: "nowrap" }}
              >
                {tabs.map((tab) => {
                  return (
                    <Tab
                      key={tab}
                      color="gray.100"
                      onClick={() => {
                        onTabActionChange && onTabActionChange(tab);
                        onTabChange && onTabChange(tab);
                      }}
                      pos="relative"
                    >
                      {tab}
                    </Tab>
                  );
                })}
                <TicketHistory />
              </TabList>
              <TabPanels mt={2}>
                {[
                  {
                    key: "transfer",
                    component: <Transfer width={width} />,
                  },
                  {
                    key: "etch",
                    component: <Etch width={width} />,
                  },
                  {
                    key: "mint",
                    component: <Mint width={width} />,
                  },
                  {
                    key: "burn",
                    component: <Burn width={width} />,
                  },
                  {
                    key: "list",
                    component: (
                      <AddRunesProvider>
                        <AddRunes width={width} />
                      </AddRunesProvider>
                    ),
                  },
                ]
                  .slice(0, tabs.length)
                  .map((tab) => (
                    <TabPanel p={0} key={tab.key}>
                      {tab.component}
                    </TabPanel>
                  ))}
              </TabPanels>
            </Tabs>
            <Footer />
          </VStack>
        </TokenListProvider>
      </ChainSelectProvider>
    </TransferProvider>
  );
}
