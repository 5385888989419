import { Stack, VStack, Text } from "@chakra-ui/react";
import {
  OmnityWidget,
  ChainName,
  TabAction,
  ChainID,
  AssetType,
} from "@omnity/widget";
import { useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";

export default function Solana() {
  const [tab, setTab] = useState(TabAction.Transfer);
  const [searchParams, setSearchParams] = useSearchParams();
  const _sourceChain = searchParams.get("sourceChain") || ChainName.Solana;
  const _targetChain = searchParams.get("targetChain") || ChainName.ICP;
  const _tokenId = searchParams.get("tokenId");

  const [sourceChain] = useState(_sourceChain);
  const [targetChain] = useState(_targetChain);
  const [tokenId] = useState(_tokenId);

  useEffect(() => {
    document.title = `${tab} | Omnity Network`;
  }, [tab]);

  return (
    <Stack bgSize="contain" bgRepeat="no-repeat" bgPos="0px 200px">
      <VStack id="widget" py={4} gap={0}>
        <Text fontSize={{ base: 18, md: 24 }} mb={4} fontWeight={300}>
          Omnity Hub - ICP ICRC
        </Text>
        <OmnityWidget
          assetType={AssetType.spl}
          customs={ChainID.sSolana}
          sourceChain={sourceChain as ChainName}
          targetChain={targetChain as ChainName}
          tab={tab}
          onTabChange={(action) => {
            setTab(action);
          }}
          tokenId={tokenId}
          network="mainnet"
          reversible
          isPeerToPeer={false}
          onParamsChange={(params) => {
            const tc = searchParams.get("targetChain");
            const sc = searchParams.get("sourceChain");
            const ti = searchParams.get("tokenId");

            if (
              !(
                sc === params.sourceChain &&
                tc === params.targetChain &&
                ti === params.tokenId
              )
            ) {
              setSearchParams({
                sourceChain: params.sourceChain ?? "",
                targetChain: params.targetChain ?? "",
                tokenId: params.tokenId ?? "",
              });
            }
          }}
        />
      </VStack>
    </Stack>
  );
}
