import { Stack, VStack, Text } from "@chakra-ui/react";
import {
  OmnityWidget,
  ChainName,
  TabAction,
  ChainID,
  AssetType,
} from "@omnity/widget";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";

export default function Runes() {
  const [tab, setTab] = useState(TabAction.Transfer);
  const [searchParams, setSearchParams] = useSearchParams();
  const _sourceChain = searchParams.get("sourceChain") || ChainName.Bitcoin;
  const _targetChain = searchParams.get("targetChain") || ChainName.Solana;
  const _tokenId = searchParams.get("tokenId");

  const [sourceChain] = useState(_sourceChain);
  const [targetChain] = useState(_targetChain);
  const [tokenId] = useState(_tokenId);

  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    const tab = decodeURIComponent(location.pathname.replace("/runes/", ""));
    const valid = Object.values(TabAction).find((t) => t.toLowerCase() === tab);
    if (valid) {
      setTab((prev) => {
        if (prev !== valid) {
          navigate(`/runes/${valid.toLowerCase()}${location.search}`, {
            replace: true,
          });
          return valid;
        }
        return prev;
      });
    }
  }, [location.pathname, location.search]);

  useEffect(() => {
    document.title = `${tab} | Omnity Network`;
  }, [tab]);

  return (
    <Stack bgSize="contain" bgRepeat="no-repeat" bgPos="0px 200px" w="100%">
      <VStack id="widget" py={4} gap={0}>
        <Text fontSize={{ base: 18, md: 24 }} mb={4} fontWeight={300}>
          Omnity Hub - Bitcoin Runes
        </Text>
        <OmnityWidget
          assetType={AssetType.runes}
          customs={ChainID.Bitcoin}
          sourceChain={sourceChain as ChainName}
          targetChain={targetChain as ChainName}
          tab={tab}
          tokenId={tokenId}
          network="mainnet"
          reversible
          isPeerToPeer={false}
          onTabChange={(action) => {
            setTab(action);
            navigate(`/runes/${action.toLowerCase()}${location.search}`, {
              replace: true,
            });
          }}
          onParamsChange={(params) => {
            const tc = searchParams.get("targetChain");
            const sc = searchParams.get("sourceChain");
            const ti = searchParams.get("tokenId");

            if (
              !(
                sc === params.sourceChain &&
                tc === params.targetChain &&
                ti === params.tokenId
              )
            ) {
              setSearchParams({
                sourceChain: params.sourceChain ?? "",
                targetChain: params.targetChain ?? "",
                tokenId: params.tokenId ?? "",
              });
            }
          }}
        />
      </VStack>
    </Stack>
  );
}
