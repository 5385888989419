import {
  ChainID,
  ChainName,
  ChainState,
  OmnityWidgetProps,
  SubmitRequire,
  TabAction,
  Token,
} from "../types";
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { parseAmount } from "@utils/format";
import { useHubContext } from "./OmnityHubContext";
import { getAvailableChainName, getChainAddr, isEvmChain } from "@utils/chains";
import ServiceFactory from "@services/Factory";
import {
  DEFAULT_TOKEN_BY_ASSET,
  MIN_CKBTC_REDEEM_AMOUNT,
  MIN_CKBTC_TRANSFER_AMOUNT,
  MIN_DOGE_TRANSFER_AMOUNT,
} from "src/utils/constants";
import { useEVMWalletKit } from "@wallet-kits/evm-wallet-kit";

interface Fee {
  fee: bigint;
  decimals: number;
  symbol: string;
}

interface TransferContextProps {
  inputSourceChain?: ChainName;
  inputTargetChain?: ChainName;
  sourceChain?: ChainName;
  targetChain?: ChainName;
  sourceAddr?: string;
  targetAddr?: string;
  useConnectWalletForReceiver: boolean;
  fee?: Fee;
  amount: string;
  reversible: boolean;
  isPeerToPeer: boolean;
  token?: Token;
  submitRequest?: SubmitRequire;
  passedProps?: OmnityWidgetProps;
  onAmountChange: (amount: string) => void;
  onTokenChange: (token?: Token) => void;
  onTargetAddrChange: (addr: string) => void;
  onSourceChainChange: (chain?: ChainName) => void;
  onTargetChainChange: (chain?: ChainName) => void;
  onRevert: () => void;
  onToggleConnectWalletForReceiver: () => void;
}

const initialState: TransferContextProps = {
  sourceChain: undefined,
  targetChain: undefined,
  sourceAddr: "",
  targetAddr: "",
  useConnectWalletForReceiver: true,
  fee: undefined,
  amount: "",
  isPeerToPeer: false,
  token: undefined,
  reversible: true,
  submitRequest: undefined,
  onAmountChange: () => {},
  onTokenChange: () => {},
  onTargetAddrChange: () => {},
  onSourceChainChange: () => {},
  onTargetChainChange: () => {},
  onRevert: () => {},
  onToggleConnectWalletForReceiver: () => {},
};

const TransferContext = createContext<TransferContextProps>(initialState);

export function useTransferContext() {
  return useContext(TransferContext);
}

export function TransferProvider(
  props: OmnityWidgetProps & {
    children: ReactNode;
  },
) {
  const {
    children,
    sourceChain: _sourceChain,
    targetChain: _targetChain,
    reversible = true,
    isPeerToPeer = false,
    tokenId,
    onParamsChange,
  } = props;

  const passedProps = {
    children,
    sourceChain: _sourceChain,
    targetChain: _targetChain,
    reversible,
    isPeerToPeer,
    tokenId,
  };
  const [amount, setAmount] = useState("");
  const [targetAddr, setTargetAddr] = useState("");
  const [sourceChain, setSourceChain] = useState(_sourceChain);
  const [targetChain, setTargetChain] = useState(_targetChain);
  const [token, setToken] = useState<Token>();
  const [useConnectWalletForReceiver, setUseConnectWalletForReceiver] =
    useState(initialState.useConnectWalletForReceiver);

  const { chains, addresses, customs, tabAction, assetType } = useHubContext();
  const { chainId: evmChainId } = useEVMWalletKit();

  useEffect(() => {
    if (tabAction === TabAction.Transfer) {
      if (!_sourceChain) {
        if (customs === ChainID.sICP) {
          setSourceChain(ChainName.ICP);
        } else if (customs === ChainID.Doge) {
          setSourceChain(ChainName.Doge);
        } else {
          setSourceChain(ChainName.Bitcoin);
        }
      }
      setAmount("");
    } else {
      setSourceChain(ChainName.ICP);
      setTargetChain(ChainName.ICP);
      setToken(undefined);
      setAmount("");
    }
  }, [tabAction, _sourceChain]);

  useEffect(() => {
    if (!token && _sourceChain && tabAction !== TabAction.Mint) {
      const chain = chains.find((c) => c.chain_name === _sourceChain);

      const _token = chain?.token_list?.find(
        (t) =>
          t.token_id ===
          (!!tokenId ? tokenId : DEFAULT_TOKEN_BY_ASSET[assetType]),
      );
      if (_token) {
        setToken(_token);
      }
    }
  }, [token?.id, _sourceChain, chains.length, tabAction]);

  const sourceAddr = getChainAddr(addresses, sourceChain);

  const submitRequest = useMemo(() => {
    if (tabAction === TabAction.Transfer) {
      if (!sourceChain) {
        return SubmitRequire.Select_Source_Chain;
      } else {
        if (sourceChain === targetChain) {
          return SubmitRequire.Invalid_Chain;
        }
        if (!sourceAddr) {
          return SubmitRequire.Connect_Source_Chain_Wallet;
        }
        if (isEvmChain(sourceChain)) {
          const evmChain = chains.find(
            (c) => c.chain_name.toLowerCase() === sourceChain.toLowerCase(),
          );
          if (evmChain && evmChain.evm_chain?.id !== evmChainId) {
            return SubmitRequire.Wrong_Network;
          }
        }
      }

      const sourceChainActive = chains.find(
        (chain) =>
          chain.chain_name.toLowerCase() === sourceChain.toLowerCase() &&
          chain.chain_state === ChainState.Active,
      );
      if (!sourceChainActive) {
        return SubmitRequire.Source_Chain_Not_Active;
      }

      if (!targetChain) {
        return SubmitRequire.Select_Target_Chain;
      }
      const targetChainActive = chains.find(
        (chain) =>
          chain.chain_name.toLowerCase() === targetChain.toLowerCase() &&
          chain.chain_state === ChainState.Active,
      );

      if (!targetChainActive) {
        return SubmitRequire.Target_Chain_Not_Active;
      }

      if (!token) return SubmitRequire.Select_Token;

      if (!amount) return SubmitRequire.Enter_Amount;

      const parsedAmount = parseAmount(amount, token.decimals);

      if (parsedAmount === 0n) {
        return SubmitRequire.Invalid_Amount;
      }
      if ((token.balance ?? 0n) < parsedAmount) {
        return SubmitRequire.Insufficient_Balance;
      }

      if (
        token.minimum_bridge_amount !== undefined &&
        parsedAmount <= BigInt(token.minimum_bridge_amount)
      ) {
        return SubmitRequire.Invalid_Amount;
      }

      if (customs === ChainID.BitcoinckBTC) {
        const minReadableAmount =
          sourceChain === ChainName.Bitcoin
            ? MIN_CKBTC_TRANSFER_AMOUNT
            : MIN_CKBTC_REDEEM_AMOUNT;
        const minAmount = Number(
          parseAmount(minReadableAmount, token.decimals).toString(),
        );
        if (parsedAmount < minAmount) {
          return SubmitRequire.Invalid_Amount;
        }
      } else if (customs === ChainID.Doge) {
        const minAmount = Number(
          parseAmount(MIN_DOGE_TRANSFER_AMOUNT, token.decimals).toString(),
        );
        if (parsedAmount < minAmount) {
          return SubmitRequire.Invalid_Amount;
        }
      }

      if (!targetAddr) {
        return SubmitRequire.Input_Receiving_Address;
      }
      if (!ServiceFactory.validateAddress(targetChain, targetAddr)) {
        return SubmitRequire.Invalid_Address;
      }
    } else if (tabAction === TabAction.Burn) {
      if (!sourceChain) {
        return SubmitRequire.Select_Burn_Chain;
      } else {
        if (!sourceAddr) {
          return SubmitRequire.Connect_Source_Chain_Wallet;
        }
        if (isEvmChain(sourceChain)) {
          const evmChain = chains.find(
            (c) => c.chain_name.toLowerCase() === sourceChain.toLowerCase(),
          );
          if (evmChain && evmChain.evm_chain?.id !== evmChainId) {
            return SubmitRequire.Wrong_Network;
          }
        }
      }

      const sourceChainActive = chains.find(
        (chain) =>
          chain.chain_name.toLowerCase() === sourceChain.toLowerCase() &&
          chain.chain_state === ChainState.Active,
      );
      if (!sourceChainActive) {
        return SubmitRequire.Source_Chain_Not_Active;
      }

      if (!token) return SubmitRequire.Select_Token;

      if (!amount) return SubmitRequire.Enter_Amount;

      if ((token.balance ?? 0n) < parseAmount(amount, token.decimals)) {
        return SubmitRequire.Insufficient_Balance;
      }
    } else if (tabAction === TabAction.Mint) {
      if (!sourceChain) {
        return SubmitRequire.Select_Mint_Chain;
      } else {
        if (!sourceAddr) {
          return SubmitRequire.Connect_Mint_Wallet;
        }
        if (isEvmChain(sourceChain)) {
          const evmChain = chains.find(
            (c) => c.chain_name.toLowerCase() === sourceChain.toLowerCase(),
          );
          if (evmChain && evmChain.evm_chain?.id !== evmChainId) {
            return SubmitRequire.Wrong_Network;
          }
        }
      }

      const mintChainActive = chains.find(
        (chain) =>
          chain.chain_name.toLowerCase() === sourceChain.toLowerCase() &&
          chain.chain_state === ChainState.Active,
      );
      if (!mintChainActive) {
        return SubmitRequire.Chain_Not_Active;
      }

      if (!token) return SubmitRequire.Select_Token;
    }

    return SubmitRequire.Confirm;
  }, [
    sourceChain,
    token?.id,
    amount,
    targetChain,
    sourceAddr,
    targetAddr,
    evmChainId,
    chains,
    customs,
    tabAction,
  ]);

  const onTargetChainChange = useCallback((chainName?: ChainName) => {
    setTargetChain(chainName);
    setTargetAddr("");
    onParamsChange &&
      onParamsChange({
        sourceChain,
        targetChain: chainName,
        tokenId: token?.token_id,
      });
  }, []);

  const onRevert = useCallback(() => {
    if (reversible) {
      setSourceChain(targetChain);
      onTargetChainChange(sourceChain);
      onParamsChange &&
        onParamsChange({
          sourceChain: targetChain,
          targetChain: sourceChain,
          tokenId: token?.token_id,
        });
    }
  }, [reversible, targetChain, sourceChain]);

  const contextValue = useMemo(() => {
    return {
      inputSourceChain: _sourceChain,
      inputTargetChain: _targetChain,
      sourceChain,
      targetChain,
      sourceAddr,
      targetAddr,
      useConnectWalletForReceiver,
      amount,
      reversible,
      token: token,
      submitRequest,
      passedProps,
      isPeerToPeer,
      onAmountChange: (v: string) => {
        setAmount(v);
      },
      onTokenChange: (t?: Token) => {
        setToken(t);
        onParamsChange &&
          onParamsChange({ sourceChain, targetChain, tokenId: t?.token_id });
        if (token?.id !== t?.id) {
          setAmount("");
        }
      },
      onTargetAddrChange: setTargetAddr,
      onSourceChainChange: (chain?: ChainName) => {
        if (chain) {
          const availableTargetChains = getAvailableChainName(chain, chains);
          if (targetChain && !availableTargetChains.includes(targetChain)) {
            onTargetChainChange(availableTargetChains[0]);
          }
        }
        setSourceChain(chain);
        onParamsChange &&
          onParamsChange({
            sourceChain: chain,
            targetChain,
            tokenId: token?.token_id,
          });
      },
      onTargetChainChange,
      onRevert,
      onToggleConnectWalletForReceiver: () => {
        setUseConnectWalletForReceiver((prev) => !prev);
      },
    };
  }, [
    sourceChain,
    targetChain,
    sourceAddr,
    targetAddr,
    amount,
    isPeerToPeer,
    token,
    _sourceChain,
    _targetChain,
    reversible,
    submitRequest,
    props,
    onRevert,
    onTargetChainChange,
    useConnectWalletForReceiver,
  ]);

  const connectedTargetAddr = getChainAddr(addresses, targetChain);

  useEffect(() => {
    if (useConnectWalletForReceiver && connectedTargetAddr) {
      setTargetAddr(connectedTargetAddr);
    } else if (!useConnectWalletForReceiver) {
      setTargetAddr("");
    }
  }, [connectedTargetAddr, useConnectWalletForReceiver, targetChain]);

  return (
    <TransferContext.Provider value={contextValue}>
      {children}
    </TransferContext.Provider>
  );
}
